<template>
  <div>
    {{ type }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {object: {type: Object, required: true}},
    computed:{
        ...mapGetters({
            types: 'sanitaire/typeAnalyses'
        }),
        type(){
            if(this.object !== null ) {
              let t = this.types.find(item => item.uid === this.object.type)
              if(t) return t.libelle
              return '-'
            }
            return '-'
        }
    }
}
</script>

<style>

</style>